<template>
    <div class="mini-program">
        <div class="header">
            <span>小程序数据统计</span>
            <div class="header-r">
                <datePicker @selector="selector"/>
            </div>
        </div>
        <div class="statistics">
            <div class="item">
                <p>访问总量</p>
                <span>{{data.visit_count}}</span>
            </div>
            <div class="item">
                <p>老用户访问量</p>
                <span>{{data.old_user_visit_count}}</span>
            </div>
            <div class="item">
                <p>新授权微信用户<br/><span>（新用户访问量）</span></p>
                <span>{{data.user_authorization}}</span>
            </div>
            <div class="item">
                <p>新授权手机号用户</p>
                <span>{{data.authorization_phone}}</span>
            </div>
        </div>
        <div class="ratio">
            <div class="item">
                <p>{{towNumber(data.order_rate || 0)}}%</p>
                <span>下单率（卡券、直充、在线点餐）</span>
            </div>
            <div class="item">
                <p>{{towNumber(data.again_order_rate || 0)}}%</p>
                <span>复购率（卡券、直充、在线点餐）</span>
            </div>
            <div class="item">
                <p>{{towNumber(data.orl_user_order_rate || 0)}}%</p>
                <span>老用户订单量占比（卡券、直充、在线点餐）</span>
            </div>
        </div>
    </div>
</template>
<script>
import datePicker from '../../../components/datePicker.vue'
export default {
    components:{
        datePicker
    },
    data(){
        return{
            data:{},
            start_time:'',// 开始时间
            end_time:'',// 结束时间
        }
    },
    created(){
        this.getData()

    },
    methods:{
        async getData(){
            const {data:res} = await this.$http.get('/c-admin/applet_user_statistics/',{
                params:{
                    start_date:this.start_time,
                    end_date:this.end_time
                }
            })
            if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
            this.data=res.data
        },
        // 接收日期选择的数据
        selector(data){
            console.log(data)
            this.start_time=data.start_time
            this.end_time=data.end_time
            this.getData()
        },
        towNumber(val) {      
            return val.toFixed(2)    
        }
    }    
}
</script>
<style lang="less" scoped>
.mini-program{
    height: 344px;
    margin-top: 12px;
    background-color: #ffffff;
    .header{
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;
        span{
            font-weight: 700;
            font-size: 16px;
            color: #252530;
        }
        .header-r{
            /deep/.box{
                min-width: 0px;
            }
            color: #828494;
            font-size: 14px;
        }
    }
    .statistics{
        display: flex;
        
        .item{
            padding-top: 20px;
            height: 110px;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            p{
                margin: 0;
                color: #3E3F54;
                font-size: 16px;
                span{
                    font-size: 12px;
                    color: #BABEC7;
                }
            }
            span{
                color: #3E3F54;
                font-size: 48px;
                font-weight: 500;
            }
        }
    }
    .ratio{
        display: flex;
        margin: 20px 30px 0 30px;
        .item{
            flex: 1;
            height: 112px;
            border-radius: 6px;
            background: linear-gradient(90deg, #FF6C75 4.01%, #FF9074 98.73%);
            border-left: 20px solid #FF4955;
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #FFFFFF;
            padding-left: 25px;
            p{
                font-weight: 500;
                font-size: 42px;
                margin: 0;
            }
            span{
                font-size: 16px;
            }
        }
    }
}
</style>