<template>
    <div class="box">
        <!-- 数据统计 -->
        <dataStatistics></dataStatistics>
        <div class="row">
            <div class="col-l">
                <!-- 第一个echarts图 -->
                <firstEcharts></firstEcharts>
                <!-- 小程序数据统计 -->
                <miniProgram></miniProgram>
                <!-- 扫码数据分析 -->
                <EwmStatistics></EwmStatistics>
                <!-- 用户留存 -->
                <userKeep></userKeep>
                <!-- 公众号数据统计 -->
                <OfficialAccountsData></OfficialAccountsData>
            </div>
            <div class="col-r">
                <div class="ranking-title">
                    <div class="title-l">近30日活动销量排行榜</div>
                    <div class="title-r">（笔）</div>
                </div>
                <div class="order-list">
                    <div class="ranking-item" v-for="(item,index) in dalesVolume" :key="index">
                        <div>
                            <template v-if="item.order_type==1">
                                【在线点餐】
                            </template>
                            <template v-else-if="item.order_type==2">
                                【卡券】
                            </template>
                            <template v-else-if="item.order_type==3">
                                【直充】
                            </template>
                            <template v-else-if="item.order_type==4">
                                【美团】
                            </template>
                            <template v-else-if="item.order_type==5">
                                【饿了么】
                            </template>
                            {{item.brand_name}}
                        </div>
                        <div>{{item.total}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dataStatistics from './components/data-statistics.vue'
import firstEcharts from './components/first-echarts.vue'
import miniProgram from './components/mini-program.vue'
import EwmStatistics from './components/Ewm-statistics.vue'
import OfficialAccountsData from './components/OfficialAccounts-data.vue'
import userKeep from './components/user-keep.vue'
export default {
    components:{
        dataStatistics,
        firstEcharts,
        miniProgram,
        EwmStatistics,
        OfficialAccountsData,
        userKeep
    },
    data(){
        return{
            dalesVolume:[],
        }
    },
    created(){
        // 近30日活动销量排行榜
		this.getDalesVolume()
    },
    methods:{
        // 近30日活动销量排行榜
		async getDalesVolume(){
			const {data:res} = await this.$http.get('tq/online_lately_brand_ranking_statis/')
			if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
			this.dalesVolume=res.data
		},
    }
}
</script>

<style lang="less" scoped>
.box{
    height:100%;
    position: relative;
    min-width: 1400px;
    margin: 0 20px;
}
.row{
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    .col-l{
        width: calc(100% - 362px);
        
    }
    .col-r{
		width: 350px;
        max-height: 100%;
        background-color: #ffffff;
		.ranking-title{
			height: 61px;
			// width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			color: #252530;
			.title-l{
				font-size: 16px;
				font-weight: 700;
			}
			.title-r{
				font-size: 12px;
				font-weight: 700;
			}
		}
		.order-list{
            height: 1630px;
			padding: 0 20px;
			overflow-y:scroll;
			.ranking-item{
				height: 30px;
				display: flex;
				justify-content: space-between;
				color: #3E3F54;
				div{
					font-size: 14px;
				}
			}
		}
		.order-list::-webkit-scrollbar {
			/*滚动条整体样式*/
			width : 6px;  /*高宽分别对应横竖滚动条的尺寸*/
			height: 1px;
		}
		.order-list::-webkit-scrollbar-thumb {
			/*滚动条里面小方块*/
			// bradius: 10px;
			box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
			background   : #BABEC7;
		}
		.order-list::-webkit-scrollbar-track {
			/*滚动条里面轨道*/
			box-shadow   : inset 0 0 5px rgba(255, 255, 255, 0.2);
			// bradius: 10px;
			background   : #ffffff;
		}
		
	}
}


</style>