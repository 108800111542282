<template>
    <div class="data-statistics">
        <div class="title">
            数据统计
            <el-tooltip  placement="bottom-start">
                <div class="tooltip-txt" slot="content">
                        今日付款订单（笔）：今天下单订单数（cps、卡券、直充、在线点餐）；<br/>
                        今日预估收益（元）：外卖cps佣金+卡券利润+直充利润+在线点餐利润；<br/>
                        本月预估收益（元）：（自然月）cps佣金+卡券利润+直充利润+在线点餐利润；<br/>
                        本月订单数（笔）：（自然月下单订单数）外卖cps订单数+卡券直充订单数+在线点餐订单数。
                    </div>
                <i class="icon-icon-question iconfont icon"></i>
            </el-tooltip>
        </div>
        <div class="content">
            <div class="content-item">
                <p>今日付款订单（笔）</p>
                <span>{{totalData.today_total}}</span>
                <div class="ratio">
                    <span>对比昨日</span>
                    <img :src="totalData.yesterday_total_rate>=0?add_src:fall_src">
                    <span>
                        <span v-if="totalData.yesterday_total_rate>=0">+</span>{{totalData.yesterday_total_rate}} %
                    </span>
                    </div>
                <div class="ratio">
                    <span>对比前日</span>
                    <img :src="totalData.before_yesterday_total_rate>=0?add_src:fall_src">
                    <span>
                        <span v-if="totalData.before_yesterday_total_rate>=0">+</span>{{totalData.before_yesterday_total_rate}} %
                    </span>
                </div>
            </div>
            <div class="content-item">
                <p>今日预估收益（元）</p>
                <span>{{totalData.today_amount}}</span>
                <div class="ratio">
                    <span>对比昨日</span>
                    <img :src="totalData.yesterday_amount_rate>=0?add_src:fall_src">
                    <span>
                        <span v-if="totalData.yesterday_amount_rate>=0">+</span>{{totalData.yesterday_amount_rate}} %
                    </span>
                    </div>
                <div class="ratio">
                    <span>对比前日</span>
                    <img :src="totalData.before_yesterday_amount_rate>=0?add_src:fall_src">
                    <span>
                        <span v-if="totalData.before_yesterday_amount_rate>=0">+</span>{{totalData.before_yesterday_amount_rate}} %
                    </span>
                </div>
            </div>
            <div class="content-item">
                <p>本月预估收益（元）</p>
                <span>{{totalData.this_month_amount}}</span>
                <div class="ratio">
                    <span>对比上月</span>
                    <img :src="totalData.last_month_amount_rate>=0?add_src:fall_src">
                    <span>
                        <span v-if="totalData.last_month_amount_rate>=0">+</span>{{totalData.last_month_amount_rate}} %
                    </span>
                    </div>
                <div class="ratio">
                    <span>对比上上月</span>
                    <img :src="totalData.two_last_month_amount_rate>=0?add_src:fall_src">
                    <span>
                        <span v-if="totalData.two_last_month_amount_rate>=0">+</span>{{totalData.two_last_month_amount_rate}} %
                    </span>
                </div>
            </div>
            <div class="content-item">
                <p>本月订单数（笔）</p>
                <span>{{totalData.this_month_total}}</span>
                <div class="ratio">
                    <span>对比上月</span>
                    <img :src="totalData.last_month_total_rate>=0?add_src:fall_src">
                    <span>
                        <span v-if="totalData.last_month_total_rate>=0">+</span>{{totalData.last_month_total_rate}} %
                    </span>
                    </div>
                <div class="ratio">
                    <span>对比上上月</span>
                    <img :src="totalData.two_last_month_total_rate>=0?add_src:fall_src">
                    <span>
                        <span v-if="totalData.two_last_month_total_rate>=0">+</span>{{totalData.two_last_month_total_rate}} %
                    </span>
                </div>
            </div>
            <div class="content-item">
                <p>卡券直充余额（元）</p>
                    <div class="balance">
                        <i v-if="isOnline" class="el-icon-more"></i>
                        <span v-else>{{balance.card_balance_obj}}</span>
                    </div>
                    <div :class="[isOnline?'icon-005yanjing-4':'icon-005yanjing-1','iconfont','yanjing-icon']" @click="isOnline=!isOnline"></div>
            </div>
            <div class="content-item">
                <p>在线点餐余额（元）</p>
                    <div class="balance">
                        <i v-if="displayStatus" class="el-icon-more"></i>
                        <span v-else>{{balance.online_balance_obj}}</span> 
                    </div>
                    <div :class="[displayStatus?'icon-005yanjing-4':'icon-005yanjing-1','iconfont','yanjing-icon']" @click="displayStatus=!displayStatus"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            displayStatus:true,//卡券直充余额是否显示
			isOnline:true,//在线点餐余额是否显示
            totalData:{},//数据统计
            balance:{},//余额
            add_src:'https://c-admin-vanilla-1251249694.cos.ap-guangzhou.myqcloud.com/statistics/add.png',
            fall_src:'https://c-admin-vanilla-1251249694.cos.ap-guangzhou.myqcloud.com/statistics/fall.png',
        }
    },
    created(){
        this.getBalance(),// 获取账户余额-飞鹅
        this.getTotalData()// 数据统计
    },
    methods:{
        // 数据统计
		async getTotalData(){
			const {data:res} = await this.$http.get('tq/online_statis/')
			if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
			this.totalData=res.data
		},
        // 获取账户余额-飞鹅
		async getBalance(){
			const {data:res} = await this.$http.get('/c-admin/balance/')
			if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
			this.balance=res.data
		},
    }
}
</script>
<style lang="less" scoped>
.data-statistics{
    height: 266px;
    background-color: #ffffff;
    .title{
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        font-size: 16px;
        color: #252530;
        font-weight: 700;
        .el-tooltip{
            color: #828494;
            margin-left: 10px;
        }
    }
    .content{
        display: flex;
        height: 205px;
        .content-item{
            flex: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
            p{
                font-size: 16px;
                color: #3E3F54;
            }
            span{
                font-size: 48px;
                font-weight: 500;
                color: #3E3F54;
            }
            .ratio{
                img{
                    width: 8px;
                    height: 9px;
                    margin-left: 10px;
                    margin-right: 5px;
                }
                span{
                    font-size: 12px;
                }
            }
            .balance{
                display:flex;
                align-items:center;
                height:65px;
            }
        }
    }
}
.yanjing-icon{
    font-size: 24px;
    margin-left:10px;
    margin-top:5px;
}
.el-icon-more{
    font-size: 48px;
    font-weight: 500;
    color: #3E3F54;
}
</style>