<template>
    <div class="data-statistics">
        <div class="title">
            扫码数据分析
            <datePicker @selector="selector"/>
        </div>
        <div class="content">
            <div class="content-item">
                <p>美团注册人数</p>
                <span>{{data.mt_register_count}}</span>
            </div>
            <div class="content-item">
                <p>美团授权手机号人数</p>
                <span>{{data.mt_register_phone}}</span>
            </div>
            <div class="content-item">
                <p>饿了么注册人数</p>
                <span>{{data.elm_register_count}}</span>
            </div>
            <div class="content-item">
                <p>饿了么授权手机号人数</p>
                <span>{{data.elm_register_phone}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import datePicker from '../../../components/datePicker.vue'
export default {
    components:{
        datePicker
    },
    data(){
        return{
            data:{},
            start_time:'',// 开始时间
            end_time:'',// 结束时间
        }
    },
    created(){
        this.getData()
    },
    methods:{
        async getData(){
            const {data:res} = await this.$http.get('/c-admin/user_register_info/',{
                params:{
                    start_date:this.start_time,
                    end_date:this.end_time
                }
            })
            if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
            this.data=res.data
        },
        // 接收日期选择的数据
        selector(data){
            this.start_time=data.start_time
            this.end_time=data.end_time
            this.getData()
        },
    }
}
</script>
<style lang="less" scoped>
.data-statistics{
    height: 208px;
    margin-top: 12px;
    background-color: #ffffff;
    .title{
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        color: #252530;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .content{
        margin-top: 15px;
        display: flex;
        height: 205px;
        .content-item{
            flex: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
            p{
                margin: 5px 0 20px 0;
                font-size: 16px;
                color: #3E3F54;
            }
            span{
                font-size: 48px;
                font-weight: 500;
                color: #3E3F54;
            }
        }
    }
}
</style>