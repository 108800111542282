<template>
    <div class="data-statistics" >
        <div class="title">
            公众号数据统计
            <div>
                公众号：
                <el-select v-model="authorizer_active" @change="authorizerChange"  placeholder="请选择" style="margin-right:50px;">
                    <el-option
                        v-for="(item,index) in authorizer_list"
                        :key="index"
                        :label="item.nick_name"
                        :value="item.appid">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="content">
            <div class="content-item">
                <p>昨日总新增粉丝</p>
                <span>{{OfficialAccounts.yesterday_new_user}}</span>
                <div class="ratio">
                    <span>对比前日</span>
                    <img :src="OfficialAccounts.compare_new_user>=0?add_src:fall_src">
                    <span>
                        <span v-if="OfficialAccounts.compare_new_user>=0">+</span>{{towNumber(OfficialAccounts.compare_new_user || 0)}} %
                    </span>
                </div>
            </div>
            <div class="content-item">
                <p>昨日总取关人数</p>
                <span>{{OfficialAccounts.yesterday_cancel_user}}</span>
                <div class="ratio">
                    <span>对比前日</span>
                    <img :src="OfficialAccounts.compare_cancel_user>=0?add_src:fall_src">
                    <span>
                        <span v-if="OfficialAccounts.compare_cancel_user>=0">+</span>{{towNumber(OfficialAccounts.compare_cancel_user || 0)}} %
                    </span>
                </div>
            </div>
            <div class="content-item">
                <p>昨日总净增粉丝</p>
                <span>{{OfficialAccounts.yesterday_only_new_user}}</span>
                <div class="ratio">
                    <span>对比前日</span>
                    <img :src="OfficialAccounts.compare_only_new_user>=0?add_src:fall_src">
                    <span>
                        <span v-if="OfficialAccounts.compare_only_new_user>=0">+</span>{{towNumber(OfficialAccounts.compare_only_new_user || 0)}} %
                    </span>
                </div>
            </div>
            <div class="content-item">
                <p>总累积关注人数</p>
                <span>{{OfficialAccounts.yesterday_cumulate_user}}</span>
                <div class="ratio">
                    <span>对比前日</span>
                    <img :src="OfficialAccounts.compare_cumulate_user>=0?add_src:fall_src">
                    <span>
                        <span v-if="OfficialAccounts.compare_cumulate_user>=0">+</span>{{towNumber(OfficialAccounts.compare_cumulate_user || 0)}} %
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import global from '../../../assets/js/global'
import axios from 'axios';
export default {
    data(){
        return{
            authorizer_active:'',
            OfficialAccounts:{},//公众号数据统计
			authorizer_list:[],//公众号列表
            add_src:'https://c-admin-vanilla-1251249694.cos.ap-guangzhou.myqcloud.com/statistics/add.png',
            fall_src:'https://c-admin-vanilla-1251249694.cos.ap-guangzhou.myqcloud.com/statistics/fall.png',
        }
    },
    created(){
        // // 获取公众号列表
		this.get_authorizer_list()
        // // 获取公众号数据
		// this.get_authorizer_user_info()
    },
    methods:{
        get_authorizer_list(){
			axios.get(global.wechatURL+"third/get_authorizer_list", {
                params: {
                    appid: 'wxe71d642d7dad2367',
                    page: 1,
                    max_page: 1000
                }
            }).then(res => {
                if(res.data.status == 200) {
                    this.authorizer_list=res.data.data
                    if(this.authorizer_list[0].appid){
                        this.authorizer_active=this.authorizer_list[0].appid
                        this.get_authorizer_user_info(this.authorizer_active)
                    }
                } else {
                    this.$message({ showClose: true, message: res.data.message, type: 'error'})
                }
            });
		},
        // 公众号选择器回调
		authorizerChange(val){
			console.log(val)
			this.authorizer_active=val;
			this.get_authorizer_user_info(this.authorizer_active)
		},
		// 获取公众号数据
		get_authorizer_user_info(appid){
			axios.get(global.wechatURL+"third/get_authorizer_user_info", {
				params: {
					// appid: 'wxeafa9dea78bba0a0',
					appid: appid,
				}
			}).then(res => {
				if(res.data.status == 200) {
					this.authorizer_false=true
					this.OfficialAccounts=res.data.data
				} else {
					this.$message({ showClose: true, message: res.data.message, type: 'error'})
					this.authorizer_false=false
				}
				
			});
		},
        towNumber(val) {      
			return val.toFixed(2)  
		}
    }
}
</script>
<style lang="less" scoped>
.data-statistics{
    margin-top: 12px;
    height: 266px;
    background-color: #ffffff;
    .title{
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        font-size: 16px;
        color: #252530;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        div{
            color: #828494;
            font-size: 14px;
            font-weight: 400;
        }
        .el-tooltip{
            
            margin-left: 10px;
        }
    }
    .content{
        display: flex;
        height: 205px;
        .content-item{
            flex: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
            p{
                font-size: 16px;
                color: #3E3F54;
            }
            span{
                font-size: 48px;
                font-weight: 500;
                color: #3E3F54;
            }
            .ratio{
                img{
                    width: 8px;
                    height: 9px;
                    margin-left: 10px;
                    margin-right: 5px;
                }
                span{
                    font-size: 12px;
                }
            }
        }
    }
}
</style>