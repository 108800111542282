<template>
    <div class="first-echarts">
        <div class="header">
            <div class="tabs">
                <div @click="clickTab(0)" :class="['tab-item',tabIndex==0? 'active':'']" >
                    收益趋势图
                    <div class="tab-line"></div>
                </div>
                <div @click="clickTab(1)" :class="['tab-item',tabIndex==1? 'active':'']" >
                    订单量趋势图
                    
                    <div class="tab-line"></div>
                </div>
                <el-tooltip effect="dark" content="结算金额=用户实付-成本价" placement="bottom-start">
                    <div class="tooltip-txt" slot="content">
                            收益趋势图：选择不同的活动类型，分别获取美团cps/饿了么cps/卡券/直充/在线点餐的收益趋势；<br/>
                            订单量趋势图：选择不同的活动类型，分别获取美团cps/饿了么cps/卡券/直充/在线点餐的订单量趋势。<br/>
                        </div>
                    <i class="icon-icon-question iconfont icon"></i>
                </el-tooltip>
            </div>
            <div class="header-r">
                <div class="title">时间范围</div>
                <el-date-picker
                    @change="changeTime"
                    :picker-options="pickerOptions"
                    v-model="time"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </div>
        </div>
        <div id="main"></div>
    </div>
</template>
<script>
import echarts from 'echarts'
export default {
    data(){
        return{
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            timeList:[],
            tabIndex:0,//tab索引值
            Ydata:[],//y轴的数据
            time:[],//时间范围
            Online_amount:[], //在线点餐-收益
            Online_total:[], //在线点餐-订单量
            Card_amount:[], //卡券-收益
            Card_total:[], //卡券-订单量
            Recharge_amount:[], //直充-收益
            Recharge_total:[], //直充-订单量
            MT_amount:[], //美团-收益
            MT_total:[], //美团-订单量
            ELM_amount:[], //饿了么点餐-收益
            ELM_total:[], //饿了么点餐-订单量
            MTELM_amount:[], //外卖cps总量-收益
            MTELM_total:[], //外卖cps总量-订单量
            start_time:'',
            end_time:''
        }
    },
    created(){
		// 获取最近七天的日期范围
		this.sevenDays()
	},
    mounted(){
		this.getEchartsData()// 获取echarts数据
    },
    methods:{
        // tab切换
		clickTab(index){
			this.tabIndex=index
			this.getEchartsData()
		},
        // 用户确认选定的值时触发
		changeTime(){
			this.start_time=this.time[0]
			this.end_time=this.time[1]
			this.getDateInfo(this.start_time,this.end_time)
			this.getEchartsData()
		},
        // 获取最近七天的日期范围
		sevenDays() {
			const end = this.formatDate(new Date());
			const start = this.formatDate(new Date().getTime() - 3600 * 1000 * 24 * 7);
			this.start_time=start
			this.end_time=end
			this.getDateInfo(start,end)
		},
        // 获取时间范围列表
		getDateInfo(begin, end) {
			let arr = []
			let str_b = begin.split('-')
			let str_e = end.split('-')
			let date_b = new Date()
			date_b.setUTCFullYear(str_b[0], str_b[1] - 1, str_b[2])
			let date_e = new Date()
			date_e.setUTCFullYear(str_e[0], str_e[1] - 1, str_e[2])
			let unixDb = date_b.getTime() - 24 * 60 * 60 * 1000
			let unixDe = date_e.getTime() - 24 * 60 * 60 * 1000
			for (let j = unixDb; j <= unixDe; ) {
				j = j + 24 * 60 * 60 * 1000
				arr.push(this.formatDate(new Date(parseInt(j))))
			}
			this.timeList=arr
		},
        // 时间格式
		formatDate(originVal) {
			const dt = new Date(originVal)
			const y = dt.getFullYear()
			const m = (dt.getMonth() + 1 + '').padStart(2, '0')
			const d = (dt.getDate() + '').padStart(2, '0')
			return `${y}-${m}-${d}`
		},
        // 获取echarts数据
		async getEchartsData(){
            const MTELM_amount = []; //外卖cps总量-收益
            const MTELM_total = []; //外卖cps总量-订单量
            const MT_amount = []; //美团-收益
            const MT_total = []; //美团-订单量
            const ELM_amount = []; //饿了么点餐-收益
            const ELM_total = []; //饿了么点餐-订单量
			const Online_amount = []; //在线点餐-收益
            const Online_total = []; //在线点餐-订单量
            const Recharge_amount = []; //直充-收益
            const Recharge_total = []; //直充-订单量
            const Card_amount = []; //卡券-收益
            const Card_total = []; //卡券-订单量
			const {data:res} = await this.$http.get('/c-admin/every_day_amount_total_statis/',{
				params:{
					start_time:this.start_time,
					end_time:this.end_time,
					query_order_type:0
				}
			})
			this.timeList.forEach((item,index)=>{
				MTELM_amount.push(0)
				MTELM_total.push(0)
                MT_amount.push(0)
                MT_total.push(0)
                ELM_amount.push(0)
                ELM_total.push(0)
                Online_amount.push(0)
                Online_total.push(0)
                Recharge_amount.push(0)
                Recharge_total.push(0)
                Card_amount.push(0)
                Card_total.push(0)
				res.data.forEach((item1,index1)=>{
					if(item==item1.date){
                        if(item1.order_type=='MTCPS_ELMCPS'){
                            MTELM_amount[index]=item1.amount
						    MTELM_total[index]=item1.total
                        }else if(item1.order_type=='MTCPS'){
                            MT_amount[index]=item1.amount
						    MT_total[index]=item1.total
                        }else if(item1.order_type=='ELMCPS'){
                            ELM_amount[index]=item1.amount
						    ELM_total[index]=item1.total
                        }else if(item1.order_type=='OnlineProduct'){
                            Online_amount[index]=item1.amount
						    Online_total[index]=item1.total
                        }else if(item1.order_type=='RechargeProduct'){
                            Recharge_amount[index]=item1.amount
						    Recharge_total[index]=item1.total
                        }else if(item1.order_type=='CardVoucher'){
                            Card_amount[index]=item1.amount
						    Card_total[index]=item1.total
                        }
					}
				})
			})
            console.log(this.Card_amount)
			if(this.tabIndex==0){
                this.statistics(MTELM_amount,MT_amount,ELM_amount,Online_amount,Recharge_amount,Card_amount)
			}else{
				this.statistics(MTELM_total,MT_total,ELM_total,Online_total,Recharge_total,Card_total)
			}
		},
        // 获取时间范围列表
		getDateInfo(begin, end) {
			let arr = []
			let str_b = begin.split('-')
			let str_e = end.split('-')
			let date_b = new Date()
			date_b.setUTCFullYear(str_b[0], str_b[1] - 1, str_b[2])
			let date_e = new Date()
			date_e.setUTCFullYear(str_e[0], str_e[1] - 1, str_e[2])
			let unixDb = date_b.getTime() - 24 * 60 * 60 * 1000
			let unixDe = date_e.getTime() - 24 * 60 * 60 * 1000
			for (let j = unixDb; j <= unixDe; ) {
				j = j + 24 * 60 * 60 * 1000
				arr.push(this.formatDate(new Date(parseInt(j))))
			}
			this.timeList=arr
		},
        // 时间格式
		formatDate(originVal) {
			const dt = new Date(originVal)
			const y = dt.getFullYear()
			const m = (dt.getMonth() + 1 + '').padStart(2, '0')
			const d = (dt.getDate() + '').padStart(2, '0')
			return `${y}-${m}-${d}`
		},
        // 渲染echarts方法
		statistics(MTELM,MT,ELM,Online,Recharge,Card) {
			var chartDom = document.getElementById('main');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['CPS总量', '美团CPS', '饿了么CPS', '在线点餐', '直充','卡券'],
                    x:'right',
                    padding:[20,55,0,0],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.timeList,
                    axisTick: {show: false,},// 坐标轴刻度
					boundaryGap: false,//坐标轴两边留白策略，类目轴和非类目轴的设置和表现不一样,设置false x轴与网格线对齐
					axisLine:{show:true,lineStyle: {color: '#BABEC7'}},//x轴线
					splitLine: {show:true,lineStyle:{color: '#BABEC7'}},//x网格线
                },
                yAxis: {
                    type: 'value',
                    axisLine:{show:false,lineStyle: {color: '#BABEC7'}},//y轴线
					axisLabel: {show: false},// 刻度标签
					axisTick: {show: false},// 坐标轴刻度
					splitLine: {show:false,lineStyle:{color: '#BABEC7'}},//y网格线
                },
                series: [
                    {
                        name: 'CPS总量',
                        type: 'line',
                        smooth: true,//平滑折线
                        data: MTELM,
                        lineStyle:{ color:'#7D68FF' },//线条自定义颜色
                        itemStyle : { normal: {label : {show: true}}},//每个折点数字
                        symbolSize: 10,   //设定实心点的大小
                        showSymbol: true, symbol: 'circle',  //设定为实心点
                        color:"#7D68FF", //设定实线点的颜色
                    },
                    {
                        name: '美团CPS',
                        type: 'line',
                        smooth: true,
                        data: MT,
                        lineStyle:{ color:'#FFAA05' },//线条自定义颜色
                        itemStyle : { normal: {label : {show: true}}},//每个折点数字
                        symbolSize: 10,   //设定实心点的大小
                        showSymbol: true, symbol: 'circle',  //设定为实心点
                        color:"#FFAA05", //设定实线点的颜色
                    },
                    {
                        name: '饿了么CPS',
                        type: 'line',
                        smooth: true,
                        data: ELM,
                        lineStyle:{ color:'#5096FF' },//线条自定义颜色
                        itemStyle : { normal: {label : {show: true}}},//每个折点数字
                        symbolSize: 10,   //设定实心点的大小
                        showSymbol: true, symbol: 'circle',  //设定为实心点
                        color:"#5096FF", //设定实线点的颜色
                    },
                    {
                        name: '在线点餐',
                        type: 'line',
                        smooth: true,
                        data: Online,
                        lineStyle:{ color:'#FF6056' },//线条自定义颜色
                        itemStyle : { normal: {label : {show: true}}},//每个折点数字
                        symbolSize: 10,   //设定实心点的大小
                        showSymbol: true, symbol: 'circle',  //设定为实心点
                        color:"#FF6056", //设定实线点的颜色
                    },
                    {
                        name: '直充',
                        type: 'line',
                        smooth: true,
                        data: Recharge,
                        lineStyle:{ color:'#18B528' },//线条自定义颜色
                        itemStyle : { normal: {label : {show: true}}},//每个折点数字
                        symbolSize: 10,   //设定实心点的大小
                        showSymbol: true, symbol: 'circle',  //设定为实心点
                        color:"#18B528", //设定实线点的颜色
                    },
                    {
                        name: '卡券',
                        type: 'line',
                        smooth: true,
                        data: Card,
                        lineStyle:{ color:'#FF80BD' },//线条自定义颜色
                        itemStyle : { normal: {label : {show: true}}},//每个折点数字
                        symbolSize: 10,   //设定实心点的大小
                        showSymbol: true, symbol: 'circle',  //设定为实心点
                        color:"#FF80BD", //设定实线点的颜色
                    }
                ]
            };
			myChart.setOption(option);// 使用刚指定的配置项和数据显示图表。
		},
    }
}
</script>
<style lang="less" scoped>
.first-echarts{
    height: 350px;
    background-color: #ffffff;
    .header{
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tabs{
            margin-top: 10px;
            display: flex;
            color: #252530;
            font-size: 16px;
            font-weight: 700;
            .tab-item{
                margin: 0 20px;
                width: 140px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                white-space:nowrap;
                position: relative;
            }
            .el-tooltip{
                color: #828494;
                margin-left: 20px;
                margin-top: 3px;
            }
            .active{
                .tab-line::before{
                    content: '';
                    width: 16px;
                    height: 4px;
                    background: #FF6056;
                    border-radius: 7px;
                    display: flex;
                    margin-top: 10px;
                }
            }
            
        }
        .header-r{
            display: flex; 
            align-items: center;
            padding-right: 20px;
            .title{
                color: #828494;
                font-size: 14px;
                margin-right: 10px;
                margin-left: 20px;
                white-space:nowrap
            }
        }
    }
    #main{
        width: 100%;
        height: 280px;
        // margin-top: -40px;
    }
}
</style>