<template>
    <div class="user-keep">
        <div class="header">
            <div class="tabs">
                <div @click="clickTab(0)" :class="['tab-item',tabIndex==0? 'active':'']" >
                    新老用户访问量对比
                    <div class="tab-line"></div>
                </div>
                <!-- <div @click="clickTab(1)" :class="['tab-item',tabIndex==1? 'active':'']" >
                    新老用户下单数据
                    <div class="tab-line"></div>
                </div> -->
                <el-tooltip effect="dark" placement="bottom-start">
                    <div class="tooltip-txt"  slot="content">
                        饿了么cps目前无权限获取到每个订单对应哪位用户，故该模块不对饿了么cps进行筛选。
                    </div>
                    <i class="icon-icon-question iconfont icon"></i>
                </el-tooltip>
            </div>
            <div class="header-r">
                <div class="title">时间范围</div>
                <el-date-picker
                    @change="changeTime"
                    :picker-options="pickerOptions"
                    v-model="time"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </div>
        </div>
        <div class="user-echarts" id="user_main">

        </div>
    </div>
</template>
<script>
import echarts from 'echarts'
export default {
    data(){
        return{
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            tabIndex:0,//tab索引值
            time:[],//时间范围
            timeList:[],
            new_data:[],
            old_data:[]
        }
    },
    created(){
        // 获取最近七天的日期范围
		this.sevenDays()
    },
    mounted(){
		this.get_new_odl_user_visit()// 获取echarts数据
    },
    methods:{
        // 获取新老用户访问数据
        async get_new_odl_user_visit(){
            const {data:res} = await this.$http.get('/c-admin/new_odl_user_visit/',{
                start_date:this.time[0],
                end_date:this.time[1],
            })
            this.new_data = []
            this.old_data = []
            this.timeList.forEach((item,index)=>{
                this.new_data.push(0);
                this.old_data.push(0);
                res.data.new_user_visit.forEach((item1,index1)=>{
                    if(item==item1.date){
                        this.new_data[index]=item1.count;
                    }
                })
                res.data.old_user_visit.forEach((item1,index1)=>{
                    if(item==item1.date){
                        this.old_data[index]=item1.count;
                    }
                })
            })
            this.statistics(this.new_data,this.old_data)
        },
        // 获取新老用户下单数据
        async getEcharts_user_order(){
            const {data:res} = await this.$http.get('/c-admin/new_odl_user_order/',{
                start_date:this.time[0],
                end_date:this.time[1],
            })
            this.new_data = []
            this.old_data = []
            this.timeList.forEach((item,index)=>{
                this.new_data.push(0);
                this.old_data.push(0);
                res.data.new.forEach((item1,index1)=>{
                    if(item==item1.date){
                        this.new_data[index]=item1.count;
                    }
                })
                res.data.old.forEach((item1,index1)=>{
                    if(item==item1.date){
                        this.old_data[index]=item1.count;
                    }
                })
            })
            this.statistics(this.new_data,this.old_data)
        },
        // 渲染echarts方法
		statistics(newData,oldData) {
			var chartDom = document.getElementById('user_main');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['新用户', '老用户'],
                    x:'right',
                    padding:[20,55,0,0],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.timeList,
                    axisTick: {show: false,},// 坐标轴刻度
					boundaryGap: false,//坐标轴两边留白策略，类目轴和非类目轴的设置和表现不一样,设置false x轴与网格线对齐
					axisLine:{show:true,lineStyle: {color: '#BABEC7'}},//x轴线
					splitLine: {show:true,lineStyle:{color: '#BABEC7'}},//x网格线
                },
                yAxis: {
                    type: 'value',
                    axisLine:{show:false,lineStyle: {color: '#BABEC7'}},//y轴线
					axisLabel: {show: false},// 刻度标签
					axisTick: {show: false},// 坐标轴刻度
					splitLine: {show:false,lineStyle:{color: '#BABEC7'}},//y网格线
                },
                series: [
                    {
                        name: '新用户',
                        type: 'line',
                        smooth: true,//平滑折线
                        data: newData,
                        lineStyle:{ color:'#FFAA05' },//线条自定义颜色
                        itemStyle : { normal: {label : {show: true}}},//每个折点数字
                        symbolSize: 10,   //设定实心点的大小
                        showSymbol: true, symbol: 'circle',  //设定为实心点
                        color:"#FFAA05", //设定实线点的颜色
                    },
                    {
                        name: '老用户',
                        type: 'line',
                        smooth: true,
                        data: oldData,
                        lineStyle:{ color:'#FF6056' },//线条自定义颜色
                        itemStyle : { normal: {label : {show: true}}},//每个折点数字
                        symbolSize: 10,   //设定实心点的大小
                        showSymbol: true, symbol: 'circle',  //设定为实心点
                        color:"#FF6056", //设定实线点的颜色
                    }
                ]
            };
			myChart.setOption(option);// 使用刚指定的配置项和数据显示图表。
		},
        // 获取最近七天的日期范围
		sevenDays() {
			const end = this.formatDate(new Date());
			const start = this.formatDate(new Date().getTime() - 3600 * 1000 * 24 * 7);
			this.start_time=start
			this.end_time=end
			this.getDateInfo(start,end)
		},
        // 时间格式
		formatDate(originVal) {
			const dt = new Date(originVal)
			const y = dt.getFullYear()
			const m = (dt.getMonth() + 1 + '').padStart(2, '0')
			const d = (dt.getDate() + '').padStart(2, '0')
			return `${y}-${m}-${d}`
		},
        // 获取时间范围列表
        getDateInfo(begin, end) {
			let arr = []
			let str_b = begin.split('-')
			let str_e = end.split('-')
			let date_b = new Date()
			date_b.setUTCFullYear(str_b[0], str_b[1] - 1, str_b[2])
			let date_e = new Date()
			date_e.setUTCFullYear(str_e[0], str_e[1] - 1, str_e[2])
			let unixDb = date_b.getTime() - 24 * 60 * 60 * 1000
			let unixDe = date_e.getTime() - 24 * 60 * 60 * 1000
			for (let j = unixDb; j <= unixDe; ) {
				j = j + 24 * 60 * 60 * 1000
				arr.push(this.formatDate(new Date(parseInt(j))))
			}
			this.timeList=arr
            console.log(arr)
		},
        // 用户确认选定的值时触发
		changeTime(){
			// this.start_time=this.time[0]
			// this.end_time=this.time[1]
            this.getDateInfo(this.time[0],this.time[1])
            if(this.tabIndex == 0){
                this.get_new_odl_user_visit()
            }else{
                this.getEcharts_user_order()
            }
		},
        // tab切换
		clickTab(index){
			this.tabIndex=index
            if(this.tabIndex == 0){
                this.get_new_odl_user_visit()
            }else{
                this.getEcharts_user_order()
            }
		},
    }
}
</script>
<style lang="less" scoped>
.user-keep{
    margin-top: 12px;
    height: 500px;
    background-color: #ffffff;
    padding-right: 20px;
    .header{
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tabs{
            display: flex;
            color: #252530;
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
            .tab-item{
                z-index: 2;
                margin: 0 20px;
                width: 140px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                white-space:nowrap;
                flex-direction: column;
            }
            .el-tooltip{
                color: #828494;
                margin-left: 20px;
                margin-top: 4px;
            }
            .active{
                .tab-line::before{
                    content: '';
                    width: 16px;
                    height: 4px;
                    background: #FF6056;
                    border-radius: 7px;
                    display: flex;
                    margin-top: 10px;
                }
            }
            
        }
        .header-r{
            display: flex;
            align-items: center;
            color: #828494;
            font-size: 14px;
            .title{
                margin-right: 10px;
            }
        }
    }
    
    .user-echarts{
        width: 100%;
        height: 360px;
    }
}
</style>